<template>
  <div class="module-master-item" v-if="masterData">
    <app-icon
      class="master-face"
      name="touxiang"
      v-show="!masterData.face"
    ></app-icon>
    <img
      class="master-face"
      v-show="masterData.face"
      :src="masterData.face"
      alt="码师头像"
    />
    <div class="master-container">
      <div class="master-info">
        <p class="master-name">{{ masterData.name }}</p>
        <p class="info-text">
          <span class="info-text">(10连</span>
          <span class="info-text_red">中{{ masterData.count }}</span>
          <span class="info-text">)</span>
        </p>
      </div>
      <p class="master-continuous">
        <span>连中</span>
        <span class="continuous-value">{{ masterData.continuous }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    masterData: {
      type: Object,
      default() {
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.module-master-item {
  @include align-center;
  padding: 0 30px;
  width: 100%;
  height: 138px;
}

.master {
  &-face {
    flex: 0 0 auto;
    margin-right: 30px;
    width: 78px;
    height: 78px;
    border-radius: 50%;
  }

  &-container {
    @include flex-between-center;
    flex-grow: 1;
    overflow: hidden;
    font-size: 32px;
  }

  &-info {
    @include align-center;

    .info-text {
      color: #7d7d7d;

      &_red {
        color: #e12c00;
      }
    }
  }

  &-name {
    @include text-ellipsis;
    margin-right: 12px;
    max-width: 180px;
    font-size: 36px;
    font-weight: 500;
  }

  &-continuous {
    @include align-center;
    font-weight: 500;
    color: #f12f00;

    .continuous-value {
      margin-left: 14px;
      font-size: 70px;
    }
  }
}
</style>
