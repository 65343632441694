<template>
  <div class="module-masterItem" v-if="data">
    <img class="master-face" :src="data.face" />
    <div class="master-container">
      <div class="master-info">
        <div class="master-name">
          <p class="master-name_text">{{ data.name }}</p>
          <slot name="nameAfter"></slot>
        </div>
        <div class="info">
          <p class="info-content">
            <span class="info-text_red">10</span>
            <span class="info-text">中</span>
            <span class="info-text_red">{{ data.count }}</span>
            <span class="info-text">连中</span>
            <span class="info-text_red">{{ data.continuous }}</span>
          </p>
          <slot name="infoAfter"></slot>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default() {
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.module-masterItem {
  @include align-center;
  padding: 0 30px;
  width: 100%;
  height: 158px;
  border-radius: 16px;
  background: #fff;
}

.master {
  &-face {
    flex: 0 0 auto;
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }

  &-container {
    @include flex-between-center;
    flex: 1;
    margin-left: 30px;
  }

  &-info {
    flex: 1;
    .info {
      @include flex-between-center;
      font-size: 28px;
      color: #7d7d7d;
      &-text_red {
        color: #e12c00;

        &:nth-of-type(3) {
          margin-right: 30px;
        }
      }
    }
  }

  &-name {
    @include align-center;
    margin-bottom: 6px;

    &_text {
      @include text-ellipsis;
      max-width: 180px;
      font-size: 36px;
      font-weight: 500;
    }
  }
}
</style>
