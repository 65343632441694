<template>
  <div class="module-forecast" v-if="forecastData">
    <forecast-item
      :class="`forecast${forecastData.payStatus ? '_open' : ''}`"
      :lotteryData="forecastData"
    >
      <template v-slot:forecast>
        <div class="forecast-cover" v-if="!forecastData.payStatus">
          <div class="pay-btn" @click="$emit('payClick', forecastData)">
            {{ hasService ? 'VIP查看' : '查看预测码 ¥2' }}
          </div>
        </div>
        <div
          class="forecast-shareBtn"
          v-if="forecastData.id && forecastData.payStatus && hasService"
          :style="{
            bottom: forecastData.forecastList.length > 1 ? '5.067vw' : '2.267vw'
          }"
          @click="$emit('shareClick')"
        >
          我要分享
        </div>
      </template>
    </forecast-item>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import forecastItem from '@/components/lotteryItem';

export default {
  components: {
    forecastItem
  },
  props: {
    hasService: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shareCoverShow: false,
      forecastData: null
    };
  },
  computed: {
    ...mapState({ restDateList: state => state.result.restDateList })
  },
  methods: {
    initialization() {
      this.forecastData = null;
    },

    setForecastData(data) {
      let forecastData = {
        id: data.forecastId || 0,
        forecastType: data.forecastType,
        payStatus: 0,
        forecastList: [],
        date: this.$dayjs(data.lottery.date).add(1, 'day'),
        no: data.lottery.no + 1
      };
      let restDateList = [...this.restDateList].reverse();
      restDateList.forEach(e => {
        if (!forecastData.date.isSame(this.$dayjs(e))) return;
        forecastData.date = forecastData.date.add(1, 'day');
      });
      if (!data.forecastId) {
        forecastData.payStatus = 1;
        forecastData.forecastStatus = 2;
        this.forecastData = forecastData;
        return;
      }
      if (forecastData.forecastType === 10) {
        forecastData.forecastList = [
          data.forecast ? data.forecast.position1 : '000000',
          data.forecast ? data.forecast.position2 : '000000',
          data.forecast ? data.forecast.position3 : '000000',
          data.forecast ? data.forecast.position4 : '000000',
          data.forecast ? data.forecast.position5 : '000000'
        ];
      } else {
        forecastData.forecastList = data.forecast
          ? data.forecast.mapArray
          : ['000000'];
        if (forecastData.forecastType < 10) {
          forecastData.forecastList = forecastData.forecastList.concat([
            '000000',
            '000000',
            '000000',
            '000000'
          ]);
        }
      }
      if (!data.forecast) {
        forecastData.forecastStatus = 0;
        this.forecastData = forecastData;
        return;
      }
      forecastData = {
        ...forecastData,
        checkNum: data.forecast.checkNum,
        rightNumber: data.forecast.rightNumber,
        date: data.forecast.date,
        payStatus: 1,
        forecastStatus: data.forecast.status
      };
      this.forecastData = forecastData;
    }
  }
};
</script>

<style lang="scss" scoped>
.forecast {
  ::v-deep .forecast-container {
    filter: blur(10px);
    transition: filter 3s linear;
  }

  &_open {
    ::v-deep .forecast-container {
      filter: blur(0);
    }

    .forecast-cover {
      animation: hideForecastCover 3s linear forwards;
    }
  }

  &-cover {
    @include flex-center-center;
    z-index: 4;
    position: absolute;
    top: -3px;
    left: 0;
    width: 100%;
    height: calc(100% + 3px);
    border: 6px solid #f8b00b;
    border-radius: 20px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 227, 169, 0.5) 100%
    );

    .pay-btn {
      @include flex-center-center;
      width: 244px;
      height: 60px;
      border-radius: 34px;
      font-size: 28px;
      font-weight: 500;
      color: #ee0f00;
      background: linear-gradient(180deg, #fff1bd 0%, #ffaf23 100%);
      box-shadow: 0px 6px 24px 0px rgba(241, 139, 0, 0.28);
    }
  }

  &-shareBtn {
    @include flex-center-center;
    z-index: 4;
    position: absolute;
    right: 30px;
    width: 166px;
    height: 68px;
    border-radius: 34px;
    font-size: 28px;
    font-weight: 500;
    color: #d30d00;
    background: linear-gradient(180deg, #fff1bd 0%, #ffaf23 100%);
    box-shadow: 0px 5px 20px 0px rgba(160, 103, 24, 0.28);
  }
}

@keyframes hideForecastCover {
  0% {
    z-index: 4;
    opacity: 1;
  }
  50% {
    z-index: 4;
    opacity: 0.5;
  }
  99% {
    opacity: 0;
  }
  100% {
    z-index: -1;
  }
}
</style>
