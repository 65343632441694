<template>
  <van-tabs
    v-model="index"
    class="module-forecastTabs"
    line-width="10.133vw"
    @change="handlTabsChange"
    v-show="tabs.length"
  >
    <van-tab
      class="lottery-tab"
      v-for="item in tabs"
      :key="item"
      :title="item | getForecastText"
    >
    </van-tab>
  </van-tabs>
</template>

<script>
export default {
  data() {
    return {
      tabs: [],
      index: 0
    };
  },
  methods: {
    setForecastTypeList(list = [], curType = 10) {
      this.tabs = list.map(e => Number(e.type));
      this.index = this.tabs.indexOf(curType);
    },

    handlTabsChange(idx) {
      this.$emit('change', this.tabs[idx]);
    }
  }
};
</script>

<style lang="scss" scoped>
.module-forecastTabs {
  &::v-deep {
    .van-tabs__wrap {
      z-index: 3;
      width: 100vw;
      height: 137px;
      overflow: hidden;
      background: #fff;
    }

    .van-tabs__nav--line {
      padding-bottom: 0;

      .van-tabs__nav--complete {
        padding: 0 30px;
      }
    }

    .van-tab {
      align-items: flex-end;
      padding: 0 0 12px;
      width: 150px;
      font-size: 32px;
      font-weight: 500;
      color: #aaa;

      &--active {
        color: #ff5225;
      }
    }

    .van-tabs__line {
      bottom: 0px;
      width: 150px;
      background: linear-gradient(90deg, #fea06a 0%, #fd4a26 100%);
    }
  }
}
</style>
