<template>
  <!-- 往期开奖 -->
  <div class="history js-history">
    <div class="header">
      <p class="header-text">开奖期号</p>
      <p class="header-text">开奖号码</p>
      <p class="header-text">预测命中</p>
    </div>
    <div class="history-list js-history-list">
      <div class="js-history-wrap">
        <p class="history-more js-history-more" v-show="loading > -1">
          {{ loadingText }}
        </p>
        <div class="history-item" v-for="item in historyList" :key="item.id">
          <div class="item-time">
            <p>{{ item.time | dateFormat('YYYY/MM/DD ddd') }}</p>
            <p :class="`item-issue${item.isCurrent ? '_current' : ''}`">
              第{{ item.issue }}期
            </p>
          </div>
          <div class="item-values">
            <p
              :class="`item-value${item.isCurrent ? '_current' : ''}`"
              v-for="(val, idx) in item.values"
              :key="idx"
            >
              {{ val }}
            </p>
          </div>
          <p :class="`item-total${item.isCurrent ? '_current' : ''}`">
            {{ item.total }}人
          </p>
        </div>
        <div class="history-item" v-if="historyData.nextIssue">
          <div class="item-time">
            <p>{{ historyData.nextTime | dateFormat('YYYY/MM/DD ddd') }}</p>
            <p class="item-issue">第{{ historyData.nextIssue }}期</p>
          </div>
          <p class="item-values_none">还未开奖，敬请期待</p>
          <p class="item-total"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFirstEnter: false,
      loading: -1,
      historyData: {
        list: [],
        nextTime: 0,
        nextIssue: 0,
        nextPage: 1,
        lastPage: 0
      }
    };
  },
  computed: {
    historyList() {
      return this.historyData.list;
    },
    loadingText() {
      let text = '';
      switch (this.loading) {
        case 0:
          text = '加载中...';
          break;
        case 1:
          text = '下滑加载更多';
          break;
        case 2:
          text = '没有更多数据了';
          break;
        case 3:
          text = '数据加载失败，点击重新加载';
          break;
      }
      return text;
    }
  },
  methods: {
    handleLoadClick() {
      if (this.loading !== 3) return;
      this.getHistoryList();
    },

    getHistoryData() {
      let { historyData } = this;
      let { nextPage } = historyData;
      if (this.loading !== -1) this.loading = 0;
      this.$http(
        'dateNumber/index',
        { page: nextPage, pageSize: 15 },
        { loading: !!this.loading }
      )
        .then(res => {
          this.setHistoryData(res);
          this.loading = res.currentPage === res.lastPage ? 2 : 1;
          this.updateScrollHeight();
        })
        .catch(() => {
          this.loading = 3;
        });
    },

    setHistoryData(data) {
      let { historyData } = this;
      let newList = [];
      newList = data.data.map((e, i) => {
        let item = {
          id: e.id,
          time: this.$dayjs(e.date).valueOf(),
          issue: e.no,
          values: e.rightNumber,
          total: e.checkNum,
          isCurrent: false
        };
        if (data.currentPage === 1 && i === 0) {
          item.isCurrent = true;
          historyData.nextTime = this.$dayjs(e.date).add(1, 'day').valueOf();
          historyData.nextIssue = item.issue + 1;
        }
        return item;
      });
      historyData.list = [...newList.reverse(), ...historyData.list];
      historyData.nextPage += 1;
      historyData.lastPage = data.lastPage;
    },

    updateScrollHeight() {
      const container = document.getElementsByClassName('js-history-wrap')[0];
      let oldScrollHeight =
        this.historyData.nextPage > 2 ? container.scrollHeight : 0;
      this.$nextTick(() => {
        document.getElementsByClassName('js-history-list')[0].scrollTop =
          container.scrollHeight - oldScrollHeight;
      });
    }
  },
  async created() {
    await this.getHistoryData();
  },
  mounted() {
    const io = new IntersectionObserver(
      async entries => {
        let entrie = entries[0];
        if (!entrie.isIntersecting || (this.loading !== 0 && this.loading > 1))
          return;
        await this.getHistoryData();
      },
      {
        threshold: 0,
        root: document.getElementsByClassName('js-history-list')[0],
        rootMargin: '-30px 0px 0px 0px'
      }
    );
    io.observe(document.getElementsByClassName('js-history-more')[0]);
  }
};
</script>

<style lang="scss" scoped>
$headerhight: 100px;

.header {
  z-index: 2;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $headerhight;
  font-size: 34px;
  font-weight: 500;
  color: #fff;
  background: linear-gradient(180deg, #fea06a 0%, #fd4a26 100%);

  &-text {
    @include flex-center-center;

    &:nth-of-type(1) {
      width: 229px;
      border-right: 2px solid #fdca8e;
    }

    &:nth-of-type(2) {
      @include hairlines(right, #fdca8e);
      width: 349px;
    }

    &:nth-of-type(3) {
      width: 172px;
    }
  }
}

.history {
  padding-top: $headerhight;

  &-more {
    @include flex-center-center;
    height: 70px;
    color: #666;
  }

  &-list {
    height: calc(100vh - $headerhight);
    overflow-y: auto;
  }

  &-item {
    @include hairlines(top, #dbdbdb);
    display: flex;
    width: 100%;
    height: 125px;
    color: #666;
    background: #fff;

    .item {
      &-time {
        padding: 24px 0 0 30px;
        width: 229px;
        height: 100%;
        border-right: 2px solid #fdca8e;
        background: rgba(255, 157, 81, 0.15);
      }

      &-issue {
        margin-top: 10px;
        font-size: 28px;

        &_current {
          @extend .item-issue;
          color: #f83100;
        }
      }

      &-values {
        @include flex-center-center;
        @include hairlines(right, #fdca8e);
        width: 349px;
        height: 100%;

        &_none {
          @extend .item-values;
          font-size: 26px;
          color: #999;
        }
      }

      &-value {
        @include flex-center-center;
        margin-right: 20px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        font-size: 36px;
        color: #4d4d4d;

        &:last-of-type {
          margin-right: 0;
        }

        &_current {
          @extend .item-value;
          color: #fff;
          background: linear-gradient(180deg, #ffa77c 0%, #ff2501 100%);
        }
      }

      &-total {
        @include flex-center-center;
        width: 172px;
        height: 100%;
        background: rgba(255, 157, 81, 0.04);

        &_current {
          @extend .item-total;
          color: #f83100;
        }
      }
    }
  }
}
</style>
