<template>
  <div :class="`module-masterList_type${type}`" v-show="masterList">
    <div class="list-cover" v-if="!open">
      <div class="pay-btn" @click="$emit('payBtnClick')">智能诊码 精准推荐</div>
    </div>
    <template v-else-if="masterList">
      <div class="list-header">
        <p :class="`list-title${type === 1 ? '_blank' : ''}`">码师推荐</p>
        <div class="list-btn" v-show="type === 2" @click="getMasterList">
          <app-icon name="refurbish" size="36"></app-icon>
          <span>换一波</span>
        </div>
      </div>
      <master-item
        class="master-item"
        v-for="item in masterList"
        :key="item.id"
        :data="item"
      >
        <router-link
          v-if="type === 1"
          class="master-link"
          :to="`/lottery/master?masterId=${item.id}&forecastType=${item.forecastType}`"
          >最新{{ item.forecastType | getForecastText }}预测</router-link
        >
        <template v-slot:nameAfter v-if="type === 2">
          <p class="master-rateOfReturn">回报率: {{ item.rateOfReturn }}%</p>
        </template>
        <template v-slot:infoAfter v-if="type === 2">
          <router-link
            class="master-link_small"
            :to="`/lottery/master?masterId=${item.id}&forecastType=10`"
            >最新预测</router-link
          >
        </template>
      </master-item>
    </template>
  </div>
</template>

<script>
import masterItem from './masterItem.vue';

export default {
  components: {
    masterItem
  },
  props: {
    masterId: {
      type: [Number, String],
      default: 0
    },
    // 列表类型， 1：预测列表里的推荐；2：回报分析里的推荐
    type: {
      type: Number,
      default: 1
    },
    // 是否开放查看
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      masterList: null
    };
  },
  methods: {
    getMasterList() {
      this.$http(
        'user/recomForecaster',
        this.$util.transformKey({
          userId: Number(this.masterId)
        })
      ).then(res => {
        this.setMasterList(res);
      });
    },

    setMasterList(list) {
      if (!list) return (this.masterList = list);
      this.masterList = list.map(e => {
        return {
          id: e.userId,
          face: e.avatarUrl,
          name: e.nickName,
          forecastType: e.type,
          count: e.count,
          continuous: e.continuous,
          rateOfReturn: e.rate || 0
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.module-masterList {
  padding: 19px 30px 30px;
  width: 100%;
  min-height: 660px;
  border-radius: 20px;

  &_type1,
  &_type2 {
    @extend .module-masterList;
  }

  &_type1 {
    background: linear-gradient(180deg, #ffcd84 0%, #ffeac2 100%);
  }

  &_type2 {
    background: url(~image/lottery/master/masterList-bg.png) 0 0 / cover
      no-repeat;
  }
}

.list {
  &-cover {
    @include flex-center-center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 660px;
    border-radius: 20px;
    background: url(~image/lottery/master/masterList-cover.png) 0 0 / cover
      no-repeat;

    .pay-btn {
      @include flex-center-center;
      width: 312px;
      height: 68px;
      border-radius: 34px;
      font-size: 28px;
      font-weight: 500;
      color: #fff;
      background: linear-gradient(180deg, #ff987c 0%, #ff5224 100%);
      box-shadow: 0px 5px 20px 0px rgba(255, 62, 0, 0.4);
    }
  }

  &-header {
    @include flex-between-center;
    margin-bottom: 28px;
    width: 100%;
    font-size: 32px;
    font-weight: 500;
    color: #fff;
  }
  &-title {
    font-size: 38px;

    &_blank {
      @extend .list-title;
      color: #000;
    }
  }

  &-btn {
    @include flex-between-center;
    width: 144px;
  }
}

.master {
  &-item {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &-rateOfReturn {
    margin-left: 20px;
    font-size: 28px;
    color: #e12c00;
  }

  &-link {
    @include flex-center-center;
    width: 200px;
    height: 64px;
    border-radius: 32px;
    font-size: 28px;
    color: #e53400;
    background: #ffe4b4;

    &_small {
      @extend .master-link;
      height: 52px;
    }
  }
}
</style>
