<template>
  <div
    :class="[
      `module-masterEchart${quan ? '_quan' : ''}`,
      'js-module-masterEchart'
    ]"
  >
    <!-- <div @click="onClick" :class="`btn${quan ? '_quan' : ''}`">全屏</div> -->
    <div :class="[`echart${quan ? '_quan' : ''}`, 'js-echart']"></div>
  </div>
</template>

<script>
import { echarts } from 'utils';

export default {
  props: {
    data: {
      type: [Object, Array],
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      parentElement: null,
      quan: false,
      chart: null
    };
  },
  watch: {
    data(data) {
      if (!data) return;
      this.chart.setOption(this.echartOption());
    }
  },
  methods: {
    async onClick() {
      const element = document.querySelector('.js-module-masterEchart');
      if (!this.parentElement) this.parentElement = element.parentElement;
      if (!this.quan) {
        document.body.appendChild(element);
      } else {
        this.parentElement.appendChild(element);
      }
      this.quan = !this.quan;
      this.$nextTick(() => {
        this.chart.clear();
        this.chart.setOption(this.echartOption());
      });
    },
    echartOption() {
      return {
        grid: {
          top: 40,
          left: 28,
          right: 20,
          bottom: 40
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            label: {
              backgroundColor: '#6a7985'
            }
          },
          formatter: ([{ data }]) => {
            return `${data[0]}<br />${
              data[1] ? `回报率：${data[1]}%` : '当日休市'
            }`;
          },
          position(pos, params, el, elRect, size) {
            let obj = { top: 10 };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 20;
            return obj;
          }
        },
        xAxis: {
          boundaryGap: ['5%', '5%'],
          axisPointer: {
            snap: true
          },
          axisTick: {
            alignWithLabel: true
          },
          type: 'time'
        },
        yAxis: {
          type: 'value',
          name: '回报率',
          axisLine: {
            show: true
          }
        },
        series: [
          {
            data: this.data,
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 0,
            lineStyle: {
              width: 4,
              color: '#FF4E1F'
            }
          }
        ]
      };
    }
  },
  mounted() {
    this.chart = echarts.init(document.querySelector('.js-echart'));
    if (!this.data) return;
    this.chart.setOption(this.echartOption());
  }
};
</script>

<style lang="scss" scoped>
.module-masterEchart {
  &_quan {
    @extend .module-masterEchart;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #f9f9f9;
  }
}

.echart {
  width: 100%;
  height: 587px;

  &_quan {
    @extend .echart;
    width: 100%;
    height: 100%;
  }
}

.btn {
  @include flex-center-center;
  width: 120px;
  height: 62px;
  color: #fff;
  background: #ff6f48;
  &_quan {
    @extend .btn;
    z-index: 10;
    position: absolute;
    top: 20px;
    left: 20px;
  }
}
</style>
