<template>
  <!-- 预测主页 -->
  <div class="page-master">
    <master-info
      class="master-info"
      :masterData.sync="masterInfo"
    ></master-info>
    <master-tabs
      v-show="masterInfo"
      :value.sync="masterTab"
      @change="handleMasterTabChange"
    ></master-tabs>
    <div class="master-container" v-show="masterTab === 1">
      <router-link class="service-link" to="/user/serviceList">
        <img class="link-img" src="~image/lottery/master/tip.gif" />
      </router-link>
      <forecast-tabs
        class="forecast-tabs"
        ref="forecastTabs"
        @change="handleForecastTypeChange"
      >
      </forecast-tabs>
      <lottery-list
        class="lottery-list"
        ref="lotteryList"
        :masterId="masterId"
        :forecastType.sync="forecastType"
      >
        <forecast-item
          class="forecast-item"
          :hasService="hasService"
          ref="forecast"
          @payClick="handleForecastPayClick"
          @shareClick="shareCoverShow = true"
        ></forecast-item>
        <master-list
          ref="masterListOfMasterTab1"
          class="master-list"
          :type="masterTab"
          :open="hasSuperService"
          @payBtnClick="handleMasterListPayBtnClick"
        ></master-list>
      </lottery-list>
    </div>
    <div class="master-container" v-if="masterTab === 2">
      <service-page
        class="service-page"
        v-if="!hasSuperService"
        @paySuccess="handleServicePaySuccess"
      >
      </service-page>
      <template v-else>
        <master-analysis
          class="master-analysis"
          :masterId="masterId"
        ></master-analysis>
        <master-list
          ref="masterListOfMasterTab2"
          class="master-list"
          :masterId="masterId"
          :type="masterTab"
          open
        ></master-list>
      </template>
    </div>
    <service-banner class="service-banner_fixed"></service-banner>
    <pay-popup
      ref="payPopup"
      :show.sync="payPopupShow"
      :content="payData.content"
      :price="payData.price"
      @confirm="handlePayConfirm"
    >
      <template>
        <router-link to="/user/serviceList" class="pay-ad">
          <img class="pay-ad_img" src="~image/lottery/master/pay-ad.png" />
        </router-link>
      </template>
    </pay-popup>
    <share-cover :show.sync="shareCoverShow"></share-cover>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import masterInfo from './components/masterInfo.vue';
import masterTabs from './components/masterTabs.vue';
import masterList from './components/masterList.vue';
import masterAnalysis from './components/masterAnalysis.vue';
import forecastTabs from './components/forecastTabs.vue';
import lotteryList from './components/lotteryList.vue';
import forecastItem from './components/forecastItem.vue';
import payPopup from '@/components/payPopup';
import serviceBanner from '@/components/serviceBanner.vue';
import servicePage from '@/components/servicePage.vue';
import shareCover from './components/shareCover.vue';

export default {
  components: {
    masterInfo,
    masterTabs,
    masterList,
    masterAnalysis,
    lotteryList,
    forecastTabs,
    forecastItem,
    payPopup,
    shareCover,
    serviceBanner,
    servicePage
  },
  data() {
    return {
      initialized: false,
      forecastType: 10,
      masterId: '',
      masterInfo: null,
      masterTab: 1,
      hasService: false,
      hasSuperService: false,
      payPopupShow: false,
      payData: {
        id: 0,
        content: '',
        price: ''
      },
      shareCoverShow: false
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      restDateList: state => state.result.restDateList
    })
  },
  watch: {
    $route(to) {
      if (to.name !== 'master') return;
      this.masterId = this.$route.query.masterId;
      this.forecastType = Number(this.$route.query.forecastType);
      this.masterTab = 1;
      this.hasService = false;
      this.hasSuperService = false;
      this.initialization();
    }
  },
  methods: {
    ...mapActions({ getRestDateList: 'result/getRestDateList' }),

    async initialization() {
      try {
        if (this.initialized) {
          this.initialized = false;
          this.$refs.lotteryList.initialization();
        }
        this.$toast.loading({});
        await this.getMasterInfo({ loading: false, dialog: false });
        if (this.masterTab === 1) {
          await this.$refs.lotteryList.getLotteryList({
            loading: false,
            dialog: false
          });
        } else if (this.hasSuperService) {
          this.$refs.masterListOfMasterTab2.getMasterList();
        }
        this.initialized = true;
      } catch (err) {
        try {
          let errData = JSON.parse(err.message);
          this.$dialog.alert({
            message: `页面初始化失败，请联系管理员！\n错误信息：${
              errData.message || '未知错误'
            }`
          });
        } catch (error) {
          let errMsg = err.message;
          this.$dialog.alert({
            message: `页面初始化失败，请联系管理员！\n错误信息：${
              errMsg || '未知错误'
            }`
          });
        }
      } finally {
        this.$toast.clear();
      }
    },

    async getMasterInfo(config = {}) {
      if (config.loading !== false) {
        this.$toast.loading({});
      }
      try {
        let { forecastType } = this;
        let forecastTypeList = [11, 12, 13, 14, 15, 21, 22, 23, 24, 25];
        let masterInfoRes = await this.$http(
          'user/predictInfo',
          { userId: this.masterId, type: forecastType },
          { ...config, loading: false }
        );
        if (!forecastTypeList.includes(forecastType)) {
          masterInfoRes.recomCoder = masterInfoRes.recomCoder.length
            ? masterInfoRes.recomCoder
            : null;
        }
        this.setMasterInfo({
          ...masterInfoRes.user,
          isFollow: masterInfoRes.isFollower
        });
        this.hasService = masterInfoRes.isService;
        this.hasSuperService = masterInfoRes.isVip;
        if (this.masterTab === 1) {
          this.$refs.forecastTabs.setForecastTypeList(
            masterInfoRes.typeList,
            forecastType
          );
          this.$refs.forecast.setForecastData({
            lottery: masterInfoRes.lastNumber,
            forecast: masterInfoRes.predict,
            forecastId: masterInfoRes.predictId,
            forecastType
          });
          this.$refs.masterListOfMasterTab1.setMasterList(
            masterInfoRes.recomCoder
          );
        }
        await this.setLotteryShare(masterInfoRes);
      } finally {
        if (config.loading !== false) {
          this.$toast.clear();
        }
      }
    },

    async setLotteryShare(data) {
      const { WX_SHARE_URL } = this.$config;
      let parentCode = sessionStorage.getItem('parentCode') || '';
      let shareUrl = `${WX_SHARE_URL}lottery/share?sharerId=${this.userInfo.id}&forecastType=${this.forecastType}&forecastId=${data.predictId}`;
      shareUrl += parentCode ? `&parentCode=${parentCode}` : '';

      if (data.predict && data.isService) {
        await this.$wx.initShare({
          title: '码师推荐',
          desc: `${this.userInfo.name}向您推荐了一个码师`,
          link: shareUrl,
          imgUrl: 'https://api.bornfortune.com/resource/image/logo.png'
        });
      } else {
        await this.$wx.initShare();
      }
    },

    setMasterInfo(data) {
      this.masterInfo = {
        id: this.masterId,
        name: data.nickName,
        face: data.avatarUrl,
        sell: null,
        count: data.count,
        continuous: data.continuous,
        record: data.total || 0,
        isFollow: data.isFollow
      };
    },

    handleMasterTabChange() {
      this.initialization();
    },

    handleMasterListPayBtnClick() {
      this.masterTab = 2;
    },

    handleForecastTypeChange(type) {
      this.forecastType = type;
      this.initialization();
    },

    handleForecastPayClick(data) {
      this.payData.id = data.id;
      this.payData.price = 2;
      this.payData.content = `付款预测(排列五：第${data.no}期)`;
      if (this.hasService) return this.handlePayConfirm();
      this.payPopupShow = true;
    },

    async handlePayConfirm(payType) {
      try {
        let { hasService } = this;
        let url = hasService ? 'unlockLog/add' : 'order/add';
        let data = {
          forecastId: this.payData.id,
          forecastType: this.forecastType === 10 ? 1 : 2
        };
        if (!this.hasService) {
          data.payType = payType;
        }
        let res = await this.$http(url, this.$util.transformKey(data));
        if (hasService || payType === 2) {
          if (payType === 2) this.$refs.payPopup.updateUserMoney(res);
          return this.handlePaySuccess();
        }
        await this.$wx
          .pay(res)
          .then(() => {
            this.handlePaySuccess();
          })
          .catch(() => this.handlePayFail());
      } catch (error) {
        this.handlePayFail(JSON.parse(error.message));
      }
    },

    handlePaySuccess() {
      this.payPopupShow = false;
      this.$toast.success('购买成功');
      this.getMasterInfo();
    },

    async handleServicePaySuccess() {
      await this.getMasterInfo();
      this.$refs.masterListOfMasterTab2.getMasterList();
    },

    handlePayFail(err) {
      let config = {
        title: (err && '购买失败') || '',
        message: (err && err.message) || '购买失败'
      };
      this.$dialog.alert(config);
    }
  },
  created() {
    // 禁止html滚动
    document.documentElement.style.overflowY = 'hidden';
    this.masterId = this.$route.query.masterId;
    this.forecastType = Number(this.$route.query.forecastType);

    if (!this.restDateList.length) this.getRestDateList();
  },
  mounted() {
    this.initialization();
  },
  beforeDestroy() {
    // 恢复html滚动
    document.documentElement.style.overflowY = '';
  }
};
</script>
<style lang="scss" scoped>
.master {
  &-info {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
  }

  &-container {
    z-index: 3;
    padding: 0 0 100px;
    margin-top: 188px;
    width: 100vw;
    height: calc(100vh - 188px);
    border-radius: 32px 32px 0 0;
    overflow: auto;
    background: #fff;
  }

  &-analysis {
    margin-bottom: 40px;
  }

  &-list {
    margin: 0 auto 40px;
    width: 690px;
  }
}

.forecast {
  &-tabs {
    z-index: 5;
    position: sticky;
    top: 0;
    left: 0;
  }

  &-item {
    margin: 40px auto;
    width: 690px;
  }
}

.service {
  &-link {
    z-index: 99;
    position: fixed;
    top: 642px;
    right: 0px;
    width: 140px;
    height: 140px;
    .link-img {
      width: 100%;
      height: 100%;
    }
  }

  &-banner {
    &_fixed {
      @extend .service-banner;
      z-index: 4;
      position: fixed;
      bottom: 0;
      left: 0;
    }
  }

  &-page {
    &::before {
      content: '';
      display: block;
      z-index: 1;
      position: fixed;
      top: 188px;
      left: 0;
      width: 100vw;
      height: 134px;
      border-radius: 32px 32px 0 0;
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(216, 216, 216, 0) 100%
      );
      opacity: 0.9;
    }
  }
}

.pay-ad {
  z-index: 9999;
  position: absolute;
  top: -128px;
  width: 690px;
  height: 124px;

  &_img {
    width: 100%;
    height: 100%;
  }
}
</style>
