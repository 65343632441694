<template>
  <div class="page-share">
    <img class="share-gold" src="~image/lottery/share/gold.png" />
    <lottery-item class="share-lottery" :lotteryData="forecastData">
      <template v-slot:header>
        <div class="lottery-header">
          <p class="lottery-issue">
            <span>开奖期号：第{{ forecastData.no }}期</span>
            <span>{{ forecastData.date }}</span>
          </p>
          <router-link
            :to="`/lottery/master?masterId=${masterData.id}&forecastType=${forecastData.forecastType}`"
          >
            <master-item
              class="lottery-master"
              :masterData="masterData"
              linkDisabled
            ></master-item>
          </router-link>
        </div>
      </template>
    </lottery-item>
    <img class="share-cover" src="~image/lottery/share/cover.png" />
  </div>
</template>

<script>
import lotteryItem from '@/components/lotteryItem';
import masterItem from '@/components/masterItem.vue';

export default {
  components: {
    lotteryItem,
    masterItem
  },
  data() {
    return {
      shareData: null,
      forecastData: null,
      masterData: null
    };
  },
  methods: {
    getForecastData() {
      this.$http('predict/share', {
        shareId: this.shareData.sharerId,
        type: this.shareData.forecastType,
        predictId: this.shareData.forecastId
      }).then(res => {
        this.setForecastData(res);
        this.setMasterData(res);
      });
    },

    setForecastData(data) {
      this.forecastData = {
        id: data.id,
        no: data.no,
        date: data.date,
        forecastList:
          !data.type || data.type === 10
            ? [
                data.position1,
                data.position2,
                data.position3,
                data.position4,
                data.position5
              ]
            : JSON.parse(data.map),
        forecastType: data.type || 10,
        forecastStatus: 0,
        rightNumber: ''
      };
    },

    setMasterData(data) {
      this.masterData = {
        id: data.user.id,
        name: data.user.nickName || `用户${data.user.id}`,
        face: data.user.avatarUrl,
        count: data.count,
        continuous: data.continuous
      };
    }
  },
  created() {
    this.$wx.hideMenuItemsAboutShare();
    let { query } = this.$route;
    this.shareData = {
      sharerId: query.sharerId,
      forecastType: query.forecastType,
      forecastId: query.forecastId
    };
    this.getForecastData();
  }
};
</script>

<style lang="scss" scoped>
.page-share {
  padding: 347px 0 0;
  min-height: 100vh;
  background: url(~image/lottery/share/bg.png) 0 0 / cover no-repeat;
}

.share {
  &-gold {
    z-index: 3;
    position: absolute;
    top: 272px;
    right: 0;
    width: 154px;
    height: 97px;
  }

  &-lottery {
    margin: 0 auto;
    padding-bottom: 344px;
    width: 690px;

    ::v-deep {
      .lottery-forecast {
        height: 382px;
      }
    }

    .lottery {
      &-header {
        z-index: 2;
      }

      &-issue {
        @include flex-between-center;
        padding: 30px $lotteryItem-paddingX;
        height: 98px;
        border-radius: 12px 12px 0 0;
        font-size: 32px;
        font-weight: 500;
        color: #fff;
        background: linear-gradient(270deg, #9259ff 0%, #d982ff 100%);
      }

      &-master {
        height: 160px;

        ::v-deep .master {
          &-face {
            width: 98px;
            height: 98px;
          }
        }
      }
    }
  }

  &-cover {
    z-index: 4;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
</style>
