<template>
  <div class="module-masterTabs">
    <div
      :class="`tab${value === 1 ? '_active' : ''}`"
      @click="handleTabClick(1)"
    >
      <app-icon
        class="tab-icon"
        :name="`master-tab1${value === 1 ? '_active' : ''}`"
        size="36px"
      ></app-icon>
      <span>预测信息</span>
    </div>
    <div
      :class="`tab${value === 2 ? '_active' : ''}`"
      class="tab"
      @click="handleTabClick(2)"
    >
      <app-icon
        class="tab-icon"
        :name="`master-tab2${value === 2 ? '_active' : ''}`"
        size="36px"
      ></app-icon>
      <span>回报分析</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1
    }
  },
  methods: {
    handleTabClick(val) {
      if (val === this.value) return;
      this.$emit('update:value', val);
      this.$emit('change', val);
    }
  }
};
</script>

<style lang="scss" scoped>
.module-masterTabs {
  display: flex;
  z-index: 4;
  position: fixed;
  top: 133px;
  left: 60px;
  width: 630px;
  height: 98px;
  border-radius: 55px;
  background: #ffffff;
  box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.12);

  .tab {
    @include flex-center-center;
    width: 50%;
    height: 100%;
    font-size: 32px;
    font-weight: 500;
    color: #b2b2b2;

    &:first-of-type::after {
      content: '';
      display: block;
      position: absolute;
      top: 25px;
      right: 0;
      width: 2px;
      height: 48px;
      background: #d6d6d6;
    }

    &_active {
      @extend .tab;
      color: #ff5218;
    }

    &-icon {
      margin-right: 12px;
    }
  }
}
</style>
